import { useState } from "react";
import { Link } from "react-router-dom";

const Menu = () => <>
    <Link to="/about">Sobre nós</Link>
    <Link to="/projectos">Projectos</Link>
    <Link to="/blog">Blog</Link>
    <Link to="/contact">Contacto</Link>
    <Link to="/associados" className="ms-lg-5 ms-md-3 btn btn-primary">Participe</Link>
</>

const Header = () => {
    const [menuState, setMenuState] = useState(false);
    const toggleMenu = () => setMenuState(!menuState);

    return <>
    <div className="d-flex flex-row" style={{minHeight: "100px"}}>
        <div className="container d-flex flex-row justify-content-between px-1 px-md-5">
            <div className="d-flex flex-row">
                <Link to="/" className="my-auto">
                    <img src="/assets/img/logo.svg" alt="MAIS" className="my-auto ms-2 ms-md-3 ms-lg-5" style={{maxHeight: "60px"}} />  
                </Link>
            </div>
            <img src="/assets/img/menu-burger.svg" alt="menu" className="d-md-none my-auto btn" style={{maxHeight: "40px"}} onClick={ e => toggleMenu() } />
            <nav className={(menuState ? "d-flex flex-column" : "d-none flex-row") + ` d-md-flex`}
                style={menuState ? {
                    position: "absolute",
                    top: "100px",
                    left: "0px",
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "hsl(45, 15%, 95%)",
                    alignItems: "flex-end",
                    gap: "1rem",
                    padding: "3rem",
                    zIndex: 1000,
                }: {}}
            >
                <Menu />
            </nav>
        </div>
    </div>
</>

}

export default Header;