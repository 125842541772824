import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const AssociadosPage: React.FC = () => {
    const [formData, setFormData] = useState({
        fullName: "",
        organization: "",
        socialImpact: "",
        email: "",
        phone: "",
        termsAccepted: false
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch("https://submitassociadosform-cog6xq47tq-uc.a.run.app", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                toast.success("Formulário enviado com sucesso.");
                setFormData({
                    fullName: "",
                    organization: "",
                    socialImpact: "",
                    email: "",
                    phone: "",
                    termsAccepted: false
                });
            } else {
                toast.error("Falha ao enviar o formulário.");
            }
        } catch (error) {
            console.error("Erro ao enviar formulário:", error);
            toast.error("Ocorreu um erro ao enviar o formulário.");
        }
        setLoading(false);
    };

    return (
        <div className="container p-2 p-md-3 p-lg-5">
            <h1 className="text-center mt-3 mt-md-5 mb-3">Torne-se Associado</h1>
            <div className="row py-3 justify-content-center">
                <div className="col-12 col-md-9">
                    <p>Um associado do <strong>MAIS</strong> é alguém comprometido com a transformação social através do desenvolvimento individual e colectivo, alinhado com os princípios do <strong>Alter-egoísmo</strong>. Ao tornar-se associado, vai participa activamente na construção de uma economia social mais justa, colaborando com projectos e iniciativas que têm impacto positivo nas comunidades.</p>
                    <p>Neste momento, o <strong>MAIS</strong> encontra-se em fase de formalização, e este formulário permite recolher os contactos necessários para futuras assinaturas de adesão oficial. <br />Agradecemos a sua atenção e interesse em fazer parte desta mudança.</p>
                    <p><strong>Bem haja!</strong></p>
                </div>
            </div>
            <div className="row py-3 justify-content-center">
                <hr className="col-12 col-md-9"/>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row py-3 justify-content-center">
                    <div className="col-9 col-md-6">
                        <strong>Nome Completo</strong>
                        <p>Por favor, insira o seu nome completo.</p>
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            className="form-control"
                            required
                        />
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-9 col-md-6">
                        <strong>Organização (se aplicável)</strong>
                        <p>Nome da organização que representa ou da qual faz parte.</p>
                        <input
                            type="text"
                            name="organization"
                            value={formData.organization}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-9 col-md-6">
                        <strong>Descrição do Impacto Social</strong>
                        <p>Descreva brevemente como as suas actividades ou as da sua organização promovem o impacto social positivo. Como você está ajudando a transformar a sociedade?</p>
                        <textarea
                            name="socialImpact"
                            value={formData.socialImpact}
                            onChange={handleChange}
                            className="form-control"
                            required
                        />
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-9 col-md-6">
                        <strong>Email de Contacto</strong>
                        <p>Por favor, insira o seu email principal para correspondência.</p>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="form-control"
                            required
                        />
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-9 col-md-6">
                        <strong>Número de Telefone</strong>
                        <p>Inclua o seu número de telefone para eventuais contactos.</p>
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="form-control"
                            required
                        />
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-9 col-md-6">
                        <strong>Aceito os Termos e Condições do MAIS</strong>
                        <p>
                            <input
                                type="checkbox"
                                name="termsAccepted"
                                checked={formData.termsAccepted}
                                onChange={handleChange}
                                className="form-check-input"
                                required
                            />
                            {' '}
                            Li e aceito os <Link to="/privacy-policy">Termos e Condições</Link> do MAIS.
                        </p>
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-9 col-md-6">
                        <button type="submit" className="btn btn-primary"
                            disabled={
                                !formData.fullName ||
                                !formData.socialImpact ||
                                !formData.email ||
                                !formData.phone ||
                                !formData.termsAccepted ||
                                !formData.email.includes('@') ||
                                !formData.email.includes('.') ||
                                formData.email.length < 5 ||
                                formData.email.length > 100 ||
                                loading
                            }    
                        >Submeter</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default AssociadosPage;