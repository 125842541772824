import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import MainLayout from "./Layouts/MainLayout";
import AboutPage from "./Pages/AboutPage";
import ManifestoPage from "./Pages/ManifestoPage";
import AssociadosPage from "./Pages/AssociadosPage";

interface AppRouterProps {
    children: React.ReactNode;
}

const AppRouter: React.FC<AppRouterProps> = ({ children }) => {
    return <>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    <Route index element={<HomePage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/manifesto" element={<ManifestoPage />} />
                    <Route path="/associados" element={<AssociadosPage />} />
                    <Route path="*" element={<AssociadosPage />} />
                </Route>
                {/* <Route path="/contact" element={<Contact />} /> */}
            </Routes>
        </BrowserRouter>
    </>
}

export default AppRouter;