import { Link } from "react-router-dom";

const Footer = () => <>
    <footer>
        <div className="container d-flex flex-column flex-md-row justify-content-between">
            <div>
                <img src="/assets/img/logo.svg" alt="MAIS" style={{maxHeight: "60px"}} />
                <p style={{color:'hsla(0,0%,0%,0.5)'}} className="m-2">Movimento Alter-egoísta<br /> de Inovação Social</p>
                <div className="d-flex flex-row flex-wrap">
                    <a href=""><img src="/assets/img/facebook.svg" alt="facebook" width={32} height={32} className="mx-1"/></a>
                    <a href=""><img src="/assets/img/x.svg" alt="x"  width={32} height={32} className="mx-1"/></a>
                    <a href=""><img src="/assets/img/instagram.svg" alt="instagram"  width={32} height={32} className="mx-1"/></a>
                    <a href=""><img src="/assets/img/linkedin.svg" alt="linkedin"  width={32} height={32} className="mx-1"/></a>
                    <a href=""><img src="/assets/img/tiktok.svg" alt="tiktok"  width={32} height={32} className="mx-1"/></a>
                    <a href=""><img src="/assets/img/whatsapp.svg" alt="whatsapp"  width={32} height={32} className="mx-1"/></a>
                    <a href=""><img src="/assets/img/youtube.svg" alt="youtube"  width={32} height={32} className="mx-1"/></a>
                </div>
            </div>

            {/* 
            

- Home
- Sobre nós
- Manifesto Alter-egoísta
- Projectos
- Blog
- Recursos
- Parceiros
- Testemunhos
- Contactos

            */}
            <div>
                <h5></h5>
                <ul>
                    <li><Link to="/">Início</Link></li>
                    <li><Link to="/about">Sobre nós</Link></li>
                    <li><Link to="/projects">Projectos</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                </ul>
            </div>
            <div>
                <ul>
                    <li><Link to="/recursos">Recursos</Link></li>
                    <li><Link to="/partners">Parceiros</Link></li>
                    <li><Link to="/testimonials">Testemunhos</Link></li>
                    <li><Link to="/contact">Contacto</Link></li>
                </ul>
            </div>
            <div>
                <h5></h5>
                <ul>
                    <li><Link to="/privacy-policy">Políticas de privacidade</Link></li>
                    <li><Link to="/terms-of-use">Termos de utilização</Link></li>
                </ul>
            </div>
        </div>
    </footer>
</>

export default Footer;