import { Link } from "react-router-dom"
import { useState } from "react"
import { toast } from "react-toastify"

const Hero = () => <>
    <div className="container d-flex flex-column flex-md-row py-0"
        style={{
            minHeight: "calc(100vh - 100px)",
            backgroundImage: "url(/assets/img/hero.svg)",
            backgroundPositionX: "left",
            backgroundPositionY: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "clamp(400px, 60vw, 700px)",
        }}
    >
        <div className="d-none d-md-flex" style={{ minWidth: "clamp(350px, 45vw, 600px)" }}>&nbsp;</div>
        <div className="d-flex flex-column my-md-auto mt-5 bg-bege p-1">
            <h1>Movimento Alter-egoísta de Inovação Social</h1>
            <p>O MAIS é uma organização dedicada a promover um novo tipo de altruísmo, onde o crescimento individual e o bem-estar coletivo andam de mãos dadas. Acreditamos que cada pessoa tem o potencial de ser um agente de mudança positiva.</p>
            <div>
                <Link to="/associados" className="btn btn-primary mt-3">Torne-se associado</Link>
                <Link to="/manifesto" className="btn btn-primary mt-3 ms-md-3"
                    style={{backgroundColor: "hsla(0, 0%, 100%, 0.5)", color: "black"}}
                >Manifesto alter-egoísta</Link>
            </div>
        </div>
    </div>
</>
const Mission = () => <>
    <div className="container d-flex flex-column flex-md-row py-0"
        style={{
            minHeight: "calc(100vh - 100px)",
            backgroundImage: "url(/assets/img/mission.svg)",
            backgroundPosition: "100% 105%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "clamp(400px, 60vw, 700px)",
        }}
    >
        <div className="d-flex flex-column my-md-auto mt-5 bg-bege p-1">
            <h2>Missão do MAIS</h2>
            <p>Capacitamos indivíduos a reconhecerem o seu papel activo na criação de valor social. Através do alter-egoísmo, promovemos uma cultura de colaboração e responsabilidade social, onde o bem-estar de todos é prioritizado.</p>
            <div>
                <Link to="/projectos" className="btn btn-primary">Conheça os nossos projectos</Link>
            </div>
        </div>
        <div className="d-none d-md-flex" style={{ minWidth: "clamp(350px, 20vw, 600px)" }}>&nbsp;</div>
    </div>
</>
const Projects = () => <>
    <div className="container d-flex flex-column flex-md-row py-5 mb-2 mb-md-5" style={{ minHeight: "90vh" }}>
        <img src="/assets/img/kid.png" alt="" className="order-md-2 m-auto" style={{ marginTop: "auto", marginBottom: "auto", maxHeight: "clamp(300px, 50vw, 500px", maxWidth: "clamp(300px, 50vw, 500px", transform: "scaleX(-1)" }} />
        <div className="d-flex flex-column my-auto">
            <h2>Futuro brilhante</h2>
            <p>Estimulamos desde cedo o desenvolvimento de valores como responsabilidade social e a capacidade de gerar impacto positivo. Através de actividades educativas e projectos colaborativos, plantamos as sementes da inovação social.</p>
            <div>
                <Link to="/projectos/kids" className="btn btn-secondary">Acompanhar crianças</Link>
            </div>
        </div>
    </div>

    <div className="container d-flex flex-column flex-md-row py-5 mb-2 mb-md-5" style={{ minHeight: "90vh" }}>
        <img src="/assets/img/teen.png" alt="" className="m-auto" style={{ marginTop: "auto", marginBottom: "auto", maxHeight: "clamp(300px, 50vw, 500px", maxWidth: "clamp(300px, 50vw, 500px", transform: "scaleX(-1)" }} />
        <div className="d-flex flex-column my-auto">
            <h2>Potencial de acção</h2>
            <p>Oferecemos aos jovens as ferramentas e recursos necessários para desenvolverem projectos de valor social. As nossas parcerias com hackerspaces e oficinas de fabricação incentivam a criatividade e o empreendedorismo social.</p>
            <div>
                <Link to="/projectos/jovens" className="btn btn-secondary">Desenvolver projectos</Link>
            </div>
        </div>
    </div>

    <div className="container d-flex flex-column flex-md-row py-5 mb-0" style={{ minHeight: "90vh" }}>
        <img src="/assets/img/adult.png" alt="" className="order-md-2 m-auto" style={{ marginTop: "auto", marginBottom: "auto", maxHeight: "clamp(300px, 50vw, 500px", maxWidth: "clamp(300px, 50vw, 500px" }} />
        <div className="d-flex flex-column my-auto">
            <h2>Liderança transformadora</h2>
            <p>Apoiamos adultos na concretização de projectos inovadores com impacto social. A nossa rede de empreendedores, investidores e cooperativas fomenta um ecossistema de negócios que vai além do lucro, visando o benefício coletivo.</p>
            <div>
                <Link to="/projectos/adultos" className="btn btn-secondary">Incubar de ideias</Link>
            </div>
        </div>
    </div>
</>

const Contact: React.FC = () => {
    const [formData, setFormData] = useState({
        email: '',
        gdpr: false
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch("https://submitcontactform-cog6xq47tq-uc.a.run.app", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                toast.success("Subscrição realizada com sucesso.", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "light"
                });
                setFormData({
                    email: '',
                    gdpr: false
                });
            } else {
                toast.error("Falha ao realizar a subscrição.", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "light"
                });
            }
        } catch (error) {
            console.error("Erro ao enviar formulário:", error);
            toast.error("Ocorreu um erro ao realizar a subscrição.", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light"
            });
        }
        setLoading(false);
    };

    return (
        <div className="container d-flex flex-column flex-md-row py-0"
            style={{
                minHeight: "100vh",
                backgroundImage: "url(/assets/img/tree.svg)",
                backgroundPositionX: "left",
                backgroundPositionY: "bottom",
                backgroundRepeat: "no-repeat",
                backgroundSize: "clamp(400px, 60vw, 700px)",
            }}
        >
            <div className="d-none d-md-flex" style={{ minWidth: "clamp(350px, 45vw, 600px)" }}>&nbsp;</div>
            <div className="d-flex flex-column my-md-auto mt-5 p-1">
                <form onSubmit={handleSubmit} className="mt-5">
                    <h2>Cresça connosco</h2>
                    <p>Junte-se ao MAIS e faça parte de uma comunidade dedicada a transformar realidades. Juntos, vamos criar um futuro mais justo e inovador.</p>
                    <input 
                        type="email" 
                        name="email" 
                        id="email" 
                        placeholder="Email de contacto" 
                        required
                        value={formData.email}
                        onChange={handleChange}
                        style={{
                            padding: "0.5rem",
                            borderRadius: "0.5rem",
                            border: "1px solid #000000",
                            width: "100%",
                            marginBottom: "1rem",
                            backgroundColor: "hsla(0, 0%, 100%, 0.5)"
                        }}
                    />
                    <div>
                        <input 
                            type="checkbox" 
                            name="gdpr" 
                            id="gdpr" 
                            required 
                            checked={formData.gdpr}
                            onChange={handleChange}
                            style={{
                                padding: "0.5rem",
                                borderRadius: "50px",
                                border: "1px solid #000000",
                                backgroundColor: "hsla(0, 0%, 100%, 0.5)"
                            }}
                        />
                        <label htmlFor="gdpr" className="ms-2">
                            Concorda com a <a href="/privacy-policy">política de privacidade</a> do MAIS
                        </label>
                    </div>
                    <div>
                        <input 
                            type="submit" 
                            className="btn btn-tertiary" 
                            value="Subscrever" 
                            style={{
                                padding: "0.5rem 1rem",
                                borderRadius: "50px",
                                border: "1px solid #000000",
                                marginTop: "1rem",
                                backgroundColor: "black",
                                color: "white"
                            }}

                            disabled={!formData.email || !formData.gdpr || !formData.email.includes('@') || !formData.email.includes('.') || formData.email.length < 5 || formData.email.length > 100 || loading} 
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};


const HomePage = () => {
    return <>
        <Hero />
        <Mission />
        <div style={{ backgroundColor: "#000000" }} className="dark">
            <Projects />
        </div>
        <div style={{ backgroundColor: "#FF5F04" }}>
            <Contact />
        </div>
    </>
}

export default HomePage