import { Link } from "react-router-dom";

const AboutPage: React.FC = () => {
    return (
        <div className="container p-2 p-md-3 p-lg-5">
            <h1 className="text-center mt-3 mt-md-5 mb-3">Sobre o MAIS</h1>
            <div className="row py-3 justify-content-center">
                <div className="col-12 col-xl-3 text-center my-3">
                    <img src="/assets/img/guilhermedasilveira.jpg" alt="Guilherme da Silveira, fundador do Movimento Alter-egoísta de Inovação Social, 2024" style={{maxWidth: "300px"}}/>
                </div>
                <div className="col-12 col-md-9 col-xl-6 my-3">
                    <p>O <strong>Movimento Alter-egoísta de Inovação Social (MAIS)</strong> foi fundado em 2024 por <a href="mailto:guilherme.silveira@movimentomais.pt"><strong>Guilherme da Silveira</strong></a>, impulsionado por uma visão de mudança social que combina desenvolvimento individual com responsabilidade coletiva.</p>
                    <p>O MAIS nasce com o propósito de actuar junto a indivíduos de todas as idades e origens, promovendo a inovação social através do crescimento pessoal e da colaboração. Acreditamos que cada ser humano, enquanto parte integrante da sociedade, possui o poder de impactar positivamente o mundo à sua volta ao mesmo tempo que evolui a nível individual.
                    </p>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-12 col-md-9">
                        <h2>O que nos Move</h2>
                        <p>A base filosófica do nosso movimento é o <strong>alter-egoísmo</strong>, uma ideologia inovadora cunhada por Guilherme da Silveira e formalizada no <Link to="/manifesto"><strong>Manifesto Alter-egoísta</strong></Link>. A filosofia que promove um equilíbrio dinâmico entre o crescimento pessoal e o bem-estar colectivo. O alter-egoísmo propõe que o ego, devidamente transformado e orientado pelo altruísmo, pode ser uma força catalisadora de mudanças positivas na sociedade.
                        </p>
                        <p>O MAIS é independente de qualquer visão política e, ao mesmo tempo, é inclusivo, colaborando com entidades e indivíduos que visem uma economia social justa e sustentável. O nosso movimento não se limita a linhas partidárias ou ideológicas; acreditamos na <strong>meritocracia</strong> como um meio de valorizar e reconhecer o esforço individual, sempre com o objetivo de promover a equidade social.
                        </p>
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-12 col-md-9">
                        <h2>A nossa Filosofia: O Alter-egoísmo</h2>
                        <p>A filosofia do alter-egoísmo, parte da premissa de que <strong>o crescimento individual e a evolução da sociedade estão interligados</strong>. Cada indivíduo, ao alcançar o seu potencial pleno, contribui diretamente para a construção de uma sociedade mais justa e equilibrada. Somos simultaneamente uma parte e o todo, e essa consciência é fundamental para criar impacto de forma equilibrada e consciente.
                        </p>
                        <p>Acreditamos que:
                            <ul>
                                <li><strong>A evolução da sociedade depende do crescimento do indivíduo:</strong> O progresso coletivo é possível quando cada pessoa busca a sua melhor versão e coloca esse crescimento ao serviço do bem comum.</li>
                                <li><strong>A meritocracia é um princípio fundamental:</strong> A valorização do esforço individual, em equilíbrio com o impacto social, permite uma sociedade mais justa e sustentável.</li>
                                <li><strong>Somos simultaneamente partes e o todo da sociedade:</strong> Cada pessoa tem um papel único a desempenhar, e é através da colaboração consciente que conseguimos evoluir como um todo.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-12 col-md-9">
                        <h2>As nossas actividades e parcerias</h2>
                        <p>O MAIS actua diretamente junto a <strong>indivíduos de todas as idades e origens</strong>, oferecendo ferramentas para o crescimento pessoal e incentivando o desenvolvimento coletivo. Trabalhamos em parceria com entidades que promovem uma <strong>economia social justa e independente</strong>, criando sinergias que estimulam a inovação social e o desenvolvimento sustentável. O nosso compromisso com a inclusão torna o MAIS um espaço aberto para todos que desejam contribuir para uma sociedade mais equitativa e colaborativa.
                        </p>
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-12 col-md-9">
                        <h2>O nosso Compromisso</h2>
                        <p>O <strong>Movimento Alter-egoísta de Inovação Social (MAIS)</strong> é, acima de tudo, um compromisso com a transformação consciente da sociedade através do desenvolvimento pessoal. Estamos empenhados em criar um futuro em que a inovação, a meritocracia e o altruísmo possam coexistir e se reforçar mutuamente, promovendo uma sociedade mais justa, colaborativa e evoluída.
                        </p>
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-12 col-md-9" style={{
                        backgroundImage: "url(/assets/img/team.png)",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "286px",
                        maxWidth: "1440px",
                    }}>&nbsp;</div>
                    <div className="col-12 col-md-9 py-3">
                        <h2>Junte-se a nós</h2>
                        <p>Seja parte desta jornada de transformação! No MAIS, acreditamos que a mudança começa com a acção individual e cresce através da colaboração. Quer seja como associado ou parceiro, a sua contribuição é fundamental para criarmos uma sociedade mais justa e equilibrada. <br />Junte-se a nós e faça parte de uma comunidade que valoriza o crescimento pessoal e o impacto social positivo. 
                        </p>
                        <p><strong>Vamos juntos construir o futuro que queremos ver!</strong></p>

                        <div className="col text-center text-lg-start my-3">
                            <Link to="/associados" className="btn btn-primary me-3 my-1">Torne-se associado</Link>
                            <Link to="/manifesto" className="btn btn-secondary text-light border my-1">Conheça o Manifesto Alter-egoísta</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage;