import './App.css';
import AppRouter from './AppRouter';

function App() {
    return (
        <AppRouter>
            <></>
        </AppRouter>
  );
}

export default App;
