import { Link } from "react-router-dom";

const ManifestoPage: React.FC = () => {
    return (
        <div className="container p-2 p-md-3 p-lg-5">
            <h1 className="text-center mt-3 mt-md-5 mb-3">Manifesto Alter-egoísta</h1>
            <div className="row py-3 justify-content-center">
                <div className="row py-3 justify-content-center">
                    <div className="col-12 col-md-9">
                        <h2>Introdução</h2>
                        <p>Vivemos num mundo que atravessa profundas transformações sociais, culturais e tecnológicas. O <strong>Movimento Alter-egoísta de Inovação Social (MAIS)</strong> nasce da necessidade de repensar o papel do indivíduo na sociedade e de criar uma nova forma de interagir com o mundo, baseada na fusão entre o altruísmo e o crescimento pessoal. O conceito de alter-egoísmo procura transcender a dicotomia entre egoísmo e altruísmo, convidando cada pessoa a integrar o seu desenvolvimento individual com o bem-estar coletivo.
                        </p>
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-12 col-md-9">
                        <h2>O que é o Alter-egoísmo</h2>
                        <p>O <strong>alter-egoísmo</strong> é um novo paradigma que reconhece a importância do crescimento individual e do autoconhecimento, enquanto simultaneamente promove o bem-estar social e o impacto coletivo. Não se trata de sacrificar o "eu" pelo "outro", nem de colocar o "outro" acima do "eu", mas de encontrar um equilíbrio onde o desenvolvimento pessoal contribua diretamente para a melhoria do mundo ao nosso redor.
                        </p>
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-12 col-md-9">
                        <h2>Os Princípios do Alter-egoísmo</h2>
                        <ol>
                            <li><strong>Interconectividade entre o Eu e o Coletivo</strong>
                                <ul>
                                    <li>O bem-estar individual está inextricavelmente ligado ao bem-estar coletivo. Ao promover o desenvolvimento pessoal, estamos simultaneamente a contribuir para uma sociedade mais justa e solidária. O alter-egoísta reconhece que cada acção individual reverbera no tecido social.</li>
                                </ul>
                            </li>
                            <li><strong>Desenvolvimento Pessoal com Propósito Social</strong>
                                <ul>
                                    <li>O crescimento individual não é um fim em si mesmo, mas um meio para contribuir activamente para um mundo melhor. Um alter-egoísta está comprometido em usar as suas habilidades, conhecimentos e recursos para gerar impacto social positivo.</li>
                                </ul>
                            </li>
                            <li><strong>Liberdade e Responsabilidade</strong>
                                <ul>
                                    <li>A liberdade de ser e agir é acompanhada pela responsabilidade de considerar o impacto das nossas escolhas. O alter-egoísmo encoraja a responsabilidade pessoal em cada comportamento e decisão, sempre com a consciência de como essas decisões afetam o bem comum.</li>
                                </ul>
                            </li>
                            <li><strong>Cooperação e Colaboração</strong>
                                <ul>
                                    <li>O alter-egoísta rejeita a competição como princípio fundamental. Acreditamos que o verdadeiro progresso acontece quando colaboramos, partilhamos e construímos juntos. A cooperação é a base para a inovação social e para o desenvolvimento sustentável.</li>
                                </ul>
                            </li>
                            <li><strong>Autenticidade e Autotransformação</strong>
                                <ul>
                                    <li>Ser alter-egoísta é um compromisso com a autenticidade. É a constante busca por uma versão mais consciente e equilibrada de si mesmo, assumindo que a transformação pessoal é uma jornada sem fim, que impulsiona o crescimento colectivo.</li>
                                </ul>
                            </li>
                            <li><strong>Respeito pela Diversidade e Inclusão</strong>
                                <ul>
                                    <li>O alter-egoísmo reconhece e valoriza a diversidade de identidades, experiências e visões de mundo. A inclusão de todos os indivíduos, independentemente de suas origens, capacidades ou crenças, é essencial para uma sociedade equilibrada e harmoniosa.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-12 col-md-9">
                        <h2>O Propósito do Movimento MAIS</h2>
                        <p>O MAIS foi criado para dar vida a esta nova forma de estar no mundo. O nosso propósito é:
                        </p>
                        <ul>
                            <li><strong>Capacitar Indivíduos</strong>: Ajudar cada pessoa a reconhecer o seu potencial único e como ele pode ser canalizado para transformar a sociedade de forma positiva.</li>
                            <li><strong>Fomentar Comunidades</strong>: Facilitar espaços de partilha, colaboração e apoio mútuo, onde as pessoas possam aprender, crescer e criar juntas.</li>
                            <li><strong>Promover a Inovação Social</strong>: Incentivar a criação de soluções inovadoras para os desafios sociais através do pensamento colaborativo e do uso consciente de tecnologias e recursos.</li>
                        </ul>
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-12 col-md-9">
                        <h2>A Visão de Futuro Alter-egoísta</h2>
                        <p>Sonhamos com um futuro onde cada pessoa se sinta realizada e livre, sabendo que o seu crescimento interior alimenta o bem-estar de todos. O alter-egoísta do futuro é aquele que reconhece a beleza da individualidade, mas que a coloca ao serviço do bem comum. Um mundo de alter-egoístas será um mundo mais pacífico, justo e colaborativo, onde o potencial humano é explorado de forma plena e ética.
                        </p>
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-12 col-md-9">
                        <h2>Uma Chamada à Acção</h2>
                        <p>Ser alter-egoísta é mais do que aderir a um movimento, é uma escolha de vida. Convidamos cada pessoa a:
                        </p>
                        <ul>
                            <li><strong>Autoanalisar-se</strong>: Refletir sobre as suas motivações, crenças e valores, questionando como podem ser alinhados com o bem-estar coletivo.</li>
                            <li><strong>Actuar com Consciência</strong>: Tomar acções que estejam em sintonia com o respeito por todos, pela natureza e pelo planeta.</li>
                            <li><strong>Colaborar</strong>: Participar em iniciativas de impacto social e partilhar ideias que promovam a mudança positiva.</li>
                        </ul>
                        <p>O mundo está em constante mudança e o alter-egoísmo é a chave para liderar essa transformação de forma consciente e sustentável. O MAIS acredita no poder transformador do indivíduo e convida-o a fazer parte desta jornada de inovação social.
                        </p>
                    </div>
                </div>
                <div className="row py-3 justify-content-center">
                    <div className="col-12 col-md-9 text-center text-lg-start my-3">
                        <Link to="/associados" className="btn btn-primary me-3 my-1">Torne-se associado</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManifestoPage;